/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Rahadian Muhammad Fujana 
==============================================================================================*/
/*@import url(https://fonts.googleapis.com/css?family=Lato:400,500,700|Quicksand:400,700);*/
@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900|Quicksand:400,500,700);
@import "../css/reset";
/*@import "reset";*/ 
@import "sass-lib";

body {  
    color: #333;
    font-size: 16px;
    font-family: 'Lato', sans-serif; 
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    opacity: 0;
    max-width: 100%;
}
img{ border:0; max-width:100%; height:auto; display: block; }
.italic { font-style: italic; }
.bold { font-weight: bold; }
.regular { font-weight: lighter; }
.fl { float: left; }
.fr { float: right; }
.clear { clear: both; }
p {
    margin: 1px 0 15px;
    line-height: 20px;
}
input, textarea, select {
    font-size: 12px;
    font-family: "Quicksand";
    border: none;
    border: 1px solid #D8D8D8;
    padding: 10px;
    margin: 0;
    color: #666;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
input[type=checkbox], input[type=radio] {
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
}
h1 { font-size: 32px; }
h2 { font-size: 28px; }
h3 { font-size: 24px; }
h4 { font-size: 20px; } 
h5 { font-size: 16px; } 
h6 { font-size: 14px; }
/* structure 
----------------------------------------------------------------------------------------------*/
section { position: relative; }
.wrapper {width: 1344px;margin: 0 auto; position: relative;}
.wrapper-2 {width: 1170px;margin: 0 auto; position: relative;}
.wrapper-fluid {width: 100%;margin: 0 auto; position: relative;
                &.hasborder { border: 1px solid $c_blue; padding: 47px; @include boxSizing(border-box); }
}
.width-auto { width: auto !important; }
.unmargin { margin: 0px !important; }
.inline { display: inline-block; }
figure { position:relative; -webkit-margin-before: 0px; -webkit-margin-after: 0px;-webkit-margin-start: 0px;-webkit-margin-end: 0px; padding: 0px; margin:0px; }
/* global
----------------------------------------------------------------------------------------------*/

.btn-blue{}
button { cursor: pointer; }
button.full , button.full-2 { @include absolute(auto,0px,-63px,auto); @include appearance(none);height: 63px; width : 50%; background: $c_lightblue; color: $c_white; border: none; text-transform: uppercase; display: block; font-size: 20px; font-family: "Lato", sans-serif; font-weight: 700; 
                              @include transition(0.2s linear all);
         &:hover{background: $c_blue;}
}
button.full-2 { @include absolute(auto,0px,0px,auto); width : 100%; }
/* header
----------------------------------------------------------------------------------------------*/
header {
    position:fixed; width: 100%; z-index:11;padding-top: 12px;padding-bottom: 12px; z-index: 100;
    div {
        &.right {
            position: relative;
            .join {
                display:inline-block;margin-top:4px;border: 3px solid $c_white; color: $c_white; font-size: 20px; text-transform: uppercase; word-wrap: break-word; text-align: center; padding: 16px 12px; font-weight: 700;
                @include transition(0.2s ease-out all);
                &:hover{background:#FFF;color:$c_blue;}
            }
            .hamburger {
                display:inline-block;cursor: pointer; padding: 8px;margin-left: 30px; position: relative; top: -8px;@include transition(0.2s ease-out all);
                span { display:block; width: 27px; height: 2px; background: $c_white; position: relative; 
                       &::before , &::after { @include transition(all 0.25s); content: '';position:absolute; width: 27px; height: 2px; background: $c_white; }
                       &::before { top: -9px; }
                       &::after { top: 9px; }
                }
                &:hover {
                    span {
                        &::before { top: -7px; }
                        &::after { top: 7px; }
                    }
                }
            }
            .language {
                position: absolute;right: -110px;top: 26px; @include transition(0.2s ease-out all);
                ul {
                    display: inline-block; position: relative;@include transition(0.2s ease-out all);
                    li { display: inline-block; padding: 7px 10px;
                         a { font-size: 18px; color: #999; position: relative;
                             &::after { @include absolute(2px, -10px, 0px, auto); width: 1px; background: $c_white; content: ''; }
                             &.active { font-weight: 700; color:#FFF;}
                         }
                         &:last-child {
                             a::after { display:none; }
                         } 
                    }
                }
            }
        }
        &.left {
            .logo {
                display: block;margin-right: 25px;float:left;overflow:hidden;@include transition(0.2s ease-out all);width:95px;;
                a{@include transition(0.2s ease-out all);display: block}
                img { max-height: 77px; max-height: 100%;}
            }
            ul {
                display: block; position: relative; bottom: -25px;@include transition(0.2s ease-out all);float:left;
                li { display: inline-block; padding: 10px 17px; 
                     a { position:relative; padding: 10px 0px; font-size: 16px; text-transform: uppercase; color: $c_white; 
                         &::before , &::after { @include transition(width 0.25s); content : ''; position:absolute; width: 0px; height: 2px; background: $c_white; }
                         &::before { top: 0px;left: -17px; }
                         &::after { bottom: 0px; right: -17px; transition-delay: 0.15s; }

                         &:hover , &.active {
                             &::before , &::after { width: 53px; }
                         }
                     }
                }
            }
        }
    }
    &:before{
        content:"";position:absolute;height: 0;top:0;left:0;right:0;background:$c_blue;@include transition(0.2s ease-out all);
    }
    &.active{
        div.right{
            height: 35px;margin:0;
            .join{opacity: 0;visibility: hidden}
            .hamburger{top:-35px}
            .language{top:0px;}
        }
        div.left{

            ul{bottom: 0px;}
            .logo{height: 40px;width: 49px;margin-right: 70px}
        }

        &:before{height:100%;}
    }
}

/*star*/
  .star-container {
        display: inline-block; margin-left: 10px; padding-left: 15px; position: relative; top: 0px;
      .rating {
          overflow: hidden;
          display: inline-block;
      }
      .rating-input {
          position: absolute;
          right: -10px;
          top: 10px;
          visibility: hidden;
      }
      .rating-star {        
          display: block;
          cursor: pointer;
          float: right;        
          width: 14px;
          height: 14px;
          background: url('../images/material/star.png');
          background-position: right;
      }
      .rating-star:hover,
      .rating-star:hover ~ .rating-star,
      .rating-input:checked ~ .rating-star {
          background-position: 0 0;
      }
      &::before { content: '|'; position: absolute; display: inline-block; left: 0px; }
  }
/*end of star*/

/* middle
----------------------------------------------------------------------------------------------*/
b {
    &.title , &.title-slider { text-transform: uppercase; font-size: 45px; line-height: 50px; display: block;
                               &.hasborder { position: relative; padding-bottom: 10px; 
                                             &::after { content:''; @include absolute(auto,auto,0px,0px); width: 50px; height: 2px; background: $c_white; }
                               }
    }
}
a {
    color:$c_blue;    
    &.highlight-more , &.view-all { font-size: 16px; font-weight: 700;
                                    span.arrow-right-blue , span.arrow-right { position: relative; display: inline-block; width: 7px; height: 7px; background : url('../images/material/arrow-right-blue.png'); top:-2px; left : 0px; margin-right: 10px; @include transition(all 0.2s ease-out); }
                                    span.arrow-right { background : url('../images/material/arrow-right.png'); }
                                    &:hover {
                                        >  span.arrow-right-blue { left: 6px; }
                                        >  span.arrow-right { left: 6px; }
                                    }
    }
    &.btn { margin: 29px 0px; padding: 25px 61px; font-size: 20px; text-transform: uppercase; font-family: "Lato",sans-serif; font-weight: 700; display: inline-block; @include boxSizing(border-box); text-align: center;
            @include transition(0.2 linear all);
            &.btn-blue { background-color: $c_lightblue; color: $c_white; @include appearance(none); border: none;
                         &:hover{background: $c_blue;}

            }
    }
    &.link-home{
        display: inline-block;margin-top: 10px;border-bottom:1px solid $c_blue;
    }

}
form {
    &.basic {
        position: relative; padding: 49px 87px 100px 87px; background-color: $c_grey2;
    }
}
span {
  &.delete { width: 20px !important; height: 20px; background:url('../images/material/delete.png'); right: -30px; top: 9px; position: absolute; display: block; cursor: pointer; }
}
div {
    &.trigger-anim , &.trigger-anim-2 { left: 0; right: 0; background-color: rgba(0,0,0,0); position: absolute; height: 10px; }
    &.clear { clear: both; }
    &.rating-list {
        text-transform: uppercase; margin: 7px 0px 7px 0px; 
        .stars {
            display: inline-block; direction: rtl;       
            &::after { content:'|'; position: relative; display: inline-block; margin-right: 10px; margin-left:5px; };
            span { width: 14px; height: 14px; display: inline-block;margin-right: 0;background: url(../images/material/star.png) no-repeat right center;cursor: pointer; 
                   &:hover, &.active {
                       background-position: left center;
                   }
                   &:hover ~ span { background-position: left center; }
            }
        }
    }

    &.status-role {
        margin-bottom: 48px;
        .status , .role { color: $c_blue; text-transform: uppercase; }
        .status { font-size: 18px; margin: 0px; }
        .role { font-size: 40px; font-weight: 900; }
    }
    &.title {
        h2 { font-weight : 700; font-size: 60px; text-transform: uppercase;margin: 5px 0px; color: $c_blue; }
        h6 { font-weight : 500; font-size: 25px; text-transform: uppercase; }
        p {
            position:relative; font-size: 20px; color: $c_grey; line-height: 26px; max-width: 500px;
            &.hasborder {
                padding-left: 150px;
                span.decor { position:absolute;top: 16px; left: 0px; width: 129px; height: 2px; content: '';background: $c_blue; }
            }
        }
        &.hasborder {
            padding-bottom: 55px; position: relative;
            span.decor { @include absolute(auto,auto,0px,0px); width: 130px; height: 2px; background: $c_blue; content: ''; }
        }
    }
    &.col-l-four { float: left; width: 25%; }
    &.menu-popup {
        @include fixed (0px,0px,auto,0px); background-color: rgba(7,91,122,0.9); height: 100vh; z-index: 300; padding-top: 78px; display: none;
        overflow:auto;
        .menu-language {
            position: absolute;right: -197px;top: -40px;
            ul {
                display: inline-block; position: relative;
                li { display: inline-block; padding: 7px 10px;
                     a { font-size: 18px; color: $c_white; position: relative;
                         &::after { @include absolute(2px, -10px, 0px, auto); width: 1px; background: $c_white; content: ''; }
                         &.active { font-weight: 700; }
                     }
                     &:last-child {
                         a::after { display:none; }
                     } 
                }
            }
        }
        .menu-close {
            position: absolute; display: flex; top: 0px; right: 0px; padding: 15px 0px; cursor: pointer; background-color: transparent;
            span {
                position: relative; width: 30px; height: 1px; background-color: transparent;
                &::before { position:absolute; display:block; content:''; top: 0px; width: 30px; height: 2px; background-color: $c_white; @include rotation(45deg);@include transition(all 0.3s ease-out); }
                &::after { position:absolute; display: block; content:''; top: 0px; left: 0px; width: 30px; height: 2px; background-color: $c_white; @include rotation(-45deg);@include transition(all 0.3s ease-out); }   
            }
            &:hover {
                span {
                    &::before { width: 26px; left: 0px; }
                    &::after { width: 26px; left: 0px; }
                }
            }
        } 
        .menu-search {
            margin-bottom: 112px;
            form {
                display: inline-block; position: relative;
                input {
                    min-width: 271px; font-size: 18px; padding: 12px 40px 12px 8px; color: $c_white; font-family :"Lato",sans-serif ;background-color: transparent; border : none; border-bottom: 1px solid $c_white;
                }
                button.search { width: 26px; height: 26px; background: transparent; background-image: url('../images/material/search.png'); background-position: center center; background-repeat: no-repeat; border: none; position: absolute; right: 0px; top: 10px; }
            }
        }
        .wrap-menu {
            position: relative;
            &::after { content:'';clear: both; }
        }
        .menu-box { float: left;width: 33.33%; padding-right: 30px; @include boxSizing(border-box);
            b { font-size: 22px; line-height: 32px; text-transform: uppercase; display: block; }
            a { color: $c_white; font-size: 18px; line-height: 28px; text-transform: capitalize; }
            ul { margin: 13px 0px; }
        }
        .menu-socmed {
            position: absolute;bottom: 0px; right: 0px;
            li {
                display: inline-block; margin-right: 10px;
                span { width: 30px; height: 30px; display: inline-block; background-repeat: no-repeat;background-size:100% auto !important;
                       &.menu-fb { background-image: url('../images/material/menu-fb.png'); }
                       &.menu-yt { background-image: url('../images/material/menu-yt.png'); }
                       &.menu-ig { background-image: url('../images/material/menu-ig.png'); }
                       &.menu-li { background-image: url('../images/material/menu-li.png'); }
                       &:hover{opacity: 0.7}
                }
                &:last-child { margin-right: 0px; }
            }
        }
    }
    &.teams-list {
        margin: 40px 0px; display: inline-block; max-width: 100%;
        &.teams-right {
            .bio {
                position: relative;
                .number { left: auto; right: 95px; }
                .image { 
                    float: right; /*background-image: url('../images/material/bg-content-teams-2.png'); */
                    img { position: absolute; bottom: 0px; right: 0px; left: auto; margin: 0px; width: auto; max-width: 340px; }
                }
                .title-name { 
                    margin-left: 79px; max-width:330px; float: left; word-break: break-all;
                    span.decor { position: absolute; bottom: -20px; top: auto; left: -32px; }
                }
            }
            .desc {
                width: 100%; display: block;
            }
        }

        .bio {
            position: relative;
            .number { position: absolute; top: 0px; left: 60px; font-size: 50px; font-weight: 700; color: $c_blue; }
            .image { 
                width: 269px; height: 392px; position: relative; float: left;/* background-image: url('../images/material/bg-content-teams.png'); */
                img { position: absolute; bottom: 0px; left: 0px; margin: 0px; width: auto; max-width: 340px; }
            }
            .title-name { 
                position : relative; margin-left: 363px; top: 96px; max-width: 500px; 
                p , b { text-transform: uppercase; display: block; }
                p.title { font-size: 18px; margin:5px 0px; }
                b.name { font-size: 20px; color: $c_blue; font-weight: 700; }
                span.decor { position: absolute; bottom: -20px; top: auto; left: -32px; }
            }
        }
        .desc {
            width: 100%; display: block;
        }
    }
    &.tutors-list {
        @include boxSizing(border-box); width: 50%; float: left; position: relative; padding-bottom: 28px;
        .logo { max-width: 60px; position: absolute; top: 15px; left: 10px; z-index: 10; }
        &:nth-child(odd) { padding-right: 24px; 
                           .triangle { right: 24px; }
        }
        &:nth-child(even) { padding-left: 24px; 
            .logo { left: 40px; }
        }
        .triangle { z-index:10; width: 40px; height: 40px; top: 15px; right: 0px; position: absolute; background-image: url('../images/material/triangle-corner.png'); background-color: $c_white; }
        figure {
            position: relative; border-bottom: 2px solid $c_blue;cursor: pointer;
        }
        .name-alma {
            @include boxSizing(border-box); position: absolute; bottom: 0px; left: 25px; right: 25px; padding: 10px 21px; background: $c_blue;
            .name { word-break: break-all; text-transform: uppercase; color: $c_white; font-size: 18px; font-weight: 500;margin-top: 6px; letter-spacing: 2px; max-width: 130px; max-height: 50px; overflow: hidden; }
            .alma {
                position: relative; min-height: 77px;
                /*&::after { position:relative; content: url(../images/material/logo-small.png); top: -15px; margin-left: 25px; }*/
                /*&::before { content:''; position: absolute; top: 20px; right: 70px; bottom: 20px; width: 1px; background: $c_white; }*/
                img { position: relative; width: auto; max-height: 45px; display: inline-block; 
                      &:first-child {
                          position:relative; margin-right: 0px;
                      }
                }
            }
        }
    }
    &.career-list {
        background: $c_blue; height: 388px; @include boxSizing(border-box); width: 47%; float: left; position: relative; margin-bottom: 53px; color: $c_white; display: inline-block;
        cursor:pointer;@include transition(0.2s linear all);
        &:nth-child(odd) { margin-right: 20px; } 
        &:nth-child(even) { margin-left: 20px; }
        &.staff { background-color: $c_grey6; }
        &.tutor { background-color: $c_blue; }
        .status-role {
            .status , .role { color: $c_white; width: 60%; margin: 0 auto;}
            .status { line-height: 45px; font-size: 22px; }
            .role { font-size: 25px; font-weight: 900; }
        }
        .triangle { width: 40px; height: 40px; position: absolute; top: 0px; right: 0px; background-image: url('../images/material/triangle-2.jpg'); }
        .inner { position: absolute; top: 50%; resize: vertical; transform: translateY(-50%); left: 0px; right: 0px; }
        .details { position: absolute; width: 62.2%; margin: auto; bottom: -30px; left: 0px; right: 0px; background: $c_white; @include boxSizing(border-box); z-index: 1; padding: 25px; text-align: center; text-transform: uppercase; }
        &:hover{
            background: $c_grey6;
        }
    }
    &.pagination {
        position: relative; width: 100%; text-align: center; font-size: 16px; margin: 80px 0px;
        span { width: 13px; height: 22px; display: inline-block; }
        a.prev , a.next { position: absolute; top: 0px; 
                          &:hover {
                              span { background-position: bottom center; } 
                          }
        }
        a.prev { left: 0px; 
                 span { background: url('../images/material/arrow-nav-left.png'); }
        }
        a.next { right: 0px; 
                 span { background: url('../images/material/arrow-nav-right.png'); }
        }
        div.row {
            margin : 0 auto;
            div.item {
                display: inline-block; position: relative; margin: 0px 10px;
                a { padding: 15px 0px; display: block; margin: 0px 10px;  }
                &::after , &::before { content:''; position:absolute; width: 0px; height: 2px; background-color: $c_blue; @include transition(all 0.2s ease-out); }
                &::before { top: 2px; left: -3px; }
                &::after { bottom: 2px; right: -3px; transition-delay: 0.2s; }
                &.active , &:hover {
                    &::after , &::before { content:''; position:absolute; width: 20px; height: 2px; background-color: $c_blue; }
                    a { color: $c_blue; }
                }
            }
        }
    }
    &.price-list {
        position:relative; background-color: $c_lightblue2; margin : 25px 0px 40px 0px;
        .price { position: absolute;top: 50%;left: 0;right: 0;transform: translateY(-50%); resize: vertical;text-align: center; max-width: 230px; 
                 p { font-family: "Quicksand",sans-serif; font-size: 40px; color: $c_white; font-weight: bold; margin-top: 0px; }
                 span { color: $c_white; font-size: 14px; }
        }       
        .list { margin-left: 231px; position: relative; padding: 55px 85px; @include boxSizing(border-box); background-size: cover; color: $c_white; font-size: 18px; line-height: 24px; 
                table {
                    tr {
                        td { padding: 6px 5px; font-family: "Lato",sans-serif; }
                    }
                }
        }
        &::after { content: ''; clear: both; display: block; }
    }
    &.file-download {
        border-bottom: 1px solid $c_grey5; padding: 0px 0px 29px 0px; margin-bottom: 19px; display: block;
        h4 { font-size: 25px; }
        .file-title { font-size: 20px; text-transform: uppercase; font-weight: 700; width: 100%; display: inline-block; }
        a.download { font-size: 15px; font-weight: 700; font-family: "Quicksand",sans-serif; display:block; position: relative; margin-bottom: 5px;
                     &::after { content: url(../images/material/download.png); position: relative; margin-left: 5px; display: inline-block; }
        }
        span { font-size: 13px; color: $c_grey; }
    }
    &.testimonial-txt {
        display: block; position: relative; padding-bottom: 26px; border-bottom: 1px solid $c_lightblue3; margin-bottom: 29px;
        .trigger-link { position: absolute; left: 0px; right: 0px; top: -90px; height: 10px; }
        .top {
            display: inline-block; position: relative;
            img { max-height: 65px; max-width: 65px; margin:0px; }
            .title-desc , .logo { display: inline-block; margin: 0px; float: left; }
            .title-desc {
                margin-left: 15px; position: relative; top: 5px;
                p , b { margin: 0px; word-break: break-word; }
            }
            &::after { content: ''; clear: both; }
        }
        .bottom {

        }
    }
    &.news-list {
        position: relative; border-bottom: 1px solid $c_grey4; padding-bottom: 38px; margin-bottom: 40px;
        &.detail {
            p.datetime { margin:0px 0px 10px 0px; }
        }
        p.datetime { font-size: 14px; text-transform: uppercase; margin:0px; }
        /*img { margin: 0px 0px !important; }*/
        .content {
            display: inline-block; margin-top: 29px;
            .logo { max-width: 129px; float: left; padding-right: 20px; padding-top: 9px; position: relative;
                    span.decor {
                        content:''; position: absolute; left: 0px; top: 14px; right: 0px; height: 2px; background-color: $c_grey; width: 132px;
                    }
            }
            .content-desc { margin-left: 150px; 
                            p { margin-top: 0px; color: $c_grey; }
            }
        }
    }
    &.input-group {
        img { width: auto !important; max-width: 100%; }
        margin: 0px 0px 30px; display: block; position: relative;
        span { font-size: 16px; color: $c_blue; text-transform: uppercase; display: block; width: 100%; font-weight: 600; 
               &.grey { color: $c_grey3; font-size: 14px; }
        }
        ol {
            li { margin: 0px !important; font-size: 16px !important; }
        }
        input[type = "text"] , input[type = "email"] , textarea { 
            @include appearance(none); background: transparent; border: none; border-bottom: 1px solid $c_grey3; display: block; width: 100%; font-size: 16px; color: $c_grey; padding: 10px 30px 10px 10px; font-family: "Lato",sans-serif; @include boxSizing(border-box); 
            position: relative;
            &.text-blue { color: $c_blue; font-weight: 700; }
        }
        input[type="checkbox"] {
            background-color: $c_blue; @include borderRadius(20px); padding: 0px; width: 17px; height: 17px;
        }
        div.check {
            padding: 10px 0px;
            div.wrap {
                display: inline-block; width: 17px; height: 18px; background-image: url('../images/material/checkbox.png'); float: left;
                &.active { background-position: bottom center; }
                input[type="checkbox"] { float: left; z-index: 30; opacity: 0;  }
            }
            span { position: relative; margin-left: 25px; top: 1px; left: 1px; color: $c_grey; font-weight: normal; }
        }
        .input_file {
            position: relative; border-bottom: 1px solid #ccc; background: url(../images/material/ico_inputfile.png) no-repeat 97% center;
            input[type="file"] { position: absolute; top: 0; left: 0; z-index: 1; opacity: 0; min-width: 100%; padding: 10px 30px 10px 10px; cursor: pointer; }
            span { 
                padding: 13px 10px; display: inline-block; width: 100%; font-size: 16px; font-weight: 300; color: $c_grey; 
            }
        }
        button.search { @include appearance(none); border: 0px solid transparent; position: absolute; bottom: 27px; right: 0px; width: 18px; height: 18px; padding: 0px; background: url('../images/material/search-2.png'); background-repeat: no-repeat; background-position: center center; }
        span.calendar { @include absolute(auto,0px,10px,auto); background: url('../images/material/calendar.png'); background-repeat: no-repeat; content: ''; width: 25px; height: 30px; }
        textarea { min-height: 60px; }
        &::after { content: ''; clear: both; }
        button { width: 100%; padding: 27px; font-size: 20px; text-transform: uppercase; font-family: "Lato",sans-serif; font-weight: 700;
                 @include transition(0.2s linear all);
                 &.blue { 
                     background-color: $c_lightblue; color: $c_white; @include appearance(none); border: none; 
                     &:hover{background: $c_blue;}     
                 }
        }
        table {
            width: 100%; margin: 20px 0px;
            td { padding: 0px 15px; position: relative;
                 &:first-child { padding:0px 15px 0px 0px; }
                 &:last-child { padding:0px 0px 0px 15px; }
            }
        }
        span.more { position: relative; text-align: right; text-transform: uppercase; cursor: pointer; 
                    &::before { content:url('../images/material/plus.png'); position: relative; margin-right: 7px; @include transition(all 0.2s ease-out); @include rotation(0deg);
                    }
                    &:hover {
                        &::before { @include rotation(90deg); }
                    }
        }
    }
    &.map {
        height: 800px; background-color: $c_lightblue; margin-bottom: 65px; position: relative;
        &::after { content:''; position: absolute; bottom: -40px; left: 0px; right: 0px; background-color: $c_grey3; height: 1px; }
    }
    &.sub-menu-mobile {
        div.side-menu { position: absolute; top: 0px; left: 48%; z-index: 15;
            ul { 
                margin-top: 30px; background-color: #015b7d; padding: 10px; width: 127px;
                li {
                    border: 0px solid #fff; padding: 10px;
                    a { color: #fff; padding-left: 0px; font-size: 14px;
                        &.active { padding-left: 0px; font-weight: bold; }
                        &::before { display:none; }
                    }
                    ul {
                        li {
                            a { font-size: 14px; }
                        }
                    }
                }
            }
        }
    }
}
section {
    /*404 not found*/
    &.not-found {
        height: 100vh; width: 100%; background-size: cover; 
        .content {
            position: absolute; top: 0px; left: 0px; bottom: 0px; width: 45%; background-color: rgba(7,91,122,0.8);
        }
        .inner { position: absolute; top: 50%; transform: translateY(-50%); resize: vertical; left: 0; right: 0; margin: auto; max-width: 75%;
                 &::after { content:''; position: absolute; bottom: -40px; right: 0px; width: 100%; max-width: 380px; height: 2px; background-color: $c_white;  }
        }
        .back { position: absolute; width: 75%; margin: 0 auto; bottom: 67px; left: 0px; right: 0px; }
        h1 , h3 {  font-family: "Quicksand",sans-serif; color: $c_white; padding-right: 90px; }
        h1 { font-size: 100px; margin-bottom: 0px;}
        h3 { font-size: 40px; line-height: 65px; }
        a.highlight-more { font-weight: 500; font-size: 25px; 
                           &:hover { color:$c_white; }
                           span.arrow-right , span.arrow-right-blue { top: -6px; }
        }
    }
    &.thank{
        h1{font-size: 64px;}
    }
    /*end of 404 not found*/

    /*main slider*/
    &.main-slider {
        position: relative; margin-bottom: 150px;
        .main-slides { 
            max-height: 100vh; 
            .slick-dots {
                position: absolute;top: 50%;left: 0;transform: translateY(-50%);resize: vertical;
                button { position: relative; @include appearance(none); border: none; background: transparent; font-size: 14px;color: $c_white; cursor: pointer; padding: 5px 0px; margin: 15px 0px;
                         &::before { @include transition(all 0.2s ease-out); position: relative; top: -4px; display:inline-block; width: 48px; height: 1px; margin-right: 10px; background: $c_white; content: ''; }

                }
                li:hover{
                    button:before{width:80px}
                }
                .slick-active {
                    button { font-size: 20px; 
                             &::before { position: relative; top: -4px; display:inline-block; width: 108px; height: 4px; margin-right: 10px; background: $c_white; content: ''; }
                    }
                    &:hover{
                        button:before{width:108px}

                    }
                }
            }
        }
        .basic {
            position: relative;
            .caption {
                z-index: 3;
                @include absolute(0px,0px,0px,0px); background-color: rgba(7,91,122,0.8);
                .inner { position: absolute;top: 50%;left: 0;right: 0;transform: translateY(-50%);
                         resize: vertical;text-align: center; }
                h1 { display: inline-block; position:relative; font-size: 65px; text-transform: uppercase; font-weight: normal; font-family: 'Quicksand', sans-serif; color: $c_white; padding: 18px 0px;
                     span.border { @include absolute(auto, 0px, 0px , 0px); content: ''; height: 2px; background: $c_white; }
                }
                p { color:$c_white;padding-left:6.6px;font-size: 24px; text-transform: uppercase; letter-spacing: 6.6px; margin: 30px 0px; }
            }
        }
        .video {
            position: relative;
            iframe { @include absolute(0,0,0,0); width: 100%; height: 100%; z-index: 10; display: none; }
            .video-pause-trigger { @include absolute(0,0,0,0); width: 100%; height: 100%; z-index: 13; cursor: pointer; }
            .caption {
                z-index: 15;
                @include absolute(0px,0px,0px,0px); @include boxSizing(border-box); max-width: 50%; background-color: rgba(7,91,122,0.8);
                .inner { position: absolute;top: 50%;left: 0;right: 0;transform: translateY(-50%);
                         resize: vertical;text-align: left;padding: 0px 100px 0px 270px; }
                h1 { display: inline-block; position:relative; font-size: 60px; text-transform: uppercase; font-weight: normal; font-family: 'Quicksand', sans-serif; color: $c_white; padding: 18px 0px; line-height: 65px;
                     span.border { position:absolute; bottom: -30px;right: 0px; content: ''; height: 2px; background: $c_white; width: 380px; }
                }
                p { color:$c_white;padding-left:6.6px;font-size: 24px; text-transform: uppercase; letter-spacing: 6.6px; margin: 30px 0px; }
                .play { @include transition(all 0.28s); position: absolute;top: 50%;right: -71px;transform: translateY(-50%);
                        resize: vertical;text-align: left;width: 133px;height: 131px; cursor: pointer; background: url('../images/material/big-play.png'); background-size: 100%;
                        &:hover { width: 120px; height: 118px; right: -65px; }
                }
            }
        }
    }
    /*end of main slider*/

    /*seen on (under main slider)*/
    &.seen-on {
        @include absolute(auto,0,-100px,0); margin: auto; z-index: 10;
        .inner { @include boxSizing(border-box);padding: 26px; background: $c_white; position: relative;
                 span.decor { content:""; position:absolute;left: 0px;top: 0px;width: 2px;height: 200px; background:$c_blue; }
        }
        .trigger-anim { top: 140px; }
        p { letter-spacing: 6px; }
        ul {
            display: block; margin-top: 10px;
            li { display: inline-block; width: 16.4%; margin-bottom: 20px; }
        }
    }
    /*end of seen on*/

    /*about intro*/
    &.about-intro {
        .trigger-anim { top: 40%; }
        .trigger-anim-2 { top: auto; bottom: 0px; }
        margin-bottom: 153px; position: relative;
        span.decor-big { @include absolute(0px,0px,0px,auto); float: right; width: 80%; background: $c_grey2; }
        div {
            &.title { position:relative; width: 50%; top: 130px; z-index: 5; }
            &.images { padding-left: 55%; max-width: 700px; position: relative;
                       figure {
                           position: relative;
                           img { width: 100%; 
                                 &.front { position: absolute; top: 100px; }
                           }
                           .decor { @include absolute(0px,0px,-90px,auto); width: 2px; background: $c_blue; }
                       }

            }
        }
        a.highlight-more{margin-top: 40px;display: inline-block;}
    }
    /*end of about intro*/

    /*our program*/
    &.program {
        position: relative;margin-bottom: 100px;
        .trigger-anim { top: 40%; }
        span {
            &.decor-1 , &.decor-2 , &.decor-3 { position: absolute; background: $c_blue;  }
            &.decor-1 { top: 0px;left: 0px; width: 138px; height: 2px;  }
            &.decor-2 { top: 211px;right: 0px; width: 128px; height: 2px;  }
            &.decor-3 { top: 0px;left: 0px; bottom: -140px; width: 2px; }
            &.big-decor { @include absolute(0px,0px,0px,0px); background-color: $c_grey2; max-width: 40%;  }
        }
        .wrapper-fluid {
            padding: 53px 0px 89px 0px;
        }
        .slider {
            position: relative;
            button {
                &.slick-prev , &.slick-next { position: absolute;top: 50%;transform: translateY(-50%); resize: vertical; z-index: 10; @include transition(all 0.18s); @include appearance(none); background: transparent; border: none; width: 70px; height: 66px; cursor: pointer; background-size: 100%;
                                              &:hover { width: 62px; height: 58px; background-size: 100%; }
                }
                &.slick-prev { left: 21px; background-image: url('../images/material/big-arrow-prev.png'); }
                &.slick-next { right: 21px; background-image: url('../images/material/big-arrow-next.png'); }
            }
            .slide {
                width: 1087px; padding-right: 20px; position: relative;
                img { width: 100%; opacity: 0.2; @include transition(opacity .9s ease-out); }
                .caption { @include absolute(0px,20px,0px,auto); width: 50%; background: rgba(69,180,202,0.95); @include boxSizing(border-box); opacity: 0;
                           b , p { color: $c_white; }
                           p { font-size: 18px; line-height: 22px; margin-top: 25px; }
                }
                .inner { position: absolute;top: 50%;left: 0;right: 0;transform: translateY(-50%);
                         resize: vertical; padding: 0px 100px; }
                &.slick-current {
                    img { opacity: 1; }
                }
            }
            &::after { content:""; position: absolute; left:1087px; top: 0px; bottom: 0px; width: 20px; background-color: $c_white; }
        }
        .caption-fixed { @include absolute(0px,auto,0px,569px); width: 41%; background: rgba(69,180,202,0.95); @include boxSizing(border-box); width: 518px;
                         b , p { color: $c_white; }
                         p { font-size: 18px; line-height: 22px; margin-top: 25px; }
        }
        .inner { position: absolute;top: 50%;left: 0;right: 0;transform: translateY(-50%);
                 resize: vertical; padding: 0px 100px; }
    }
    /*end of our program*/

    /*work*/
    &.work {
        margin-bottom: 126px;
        .trigger-anim { top: 40%; }
        .trigger-anim-2 { top: auto; bottom: -200px; }
        span.decor { position: absolute; bottom: 0px; right: 0px; width: 2px; height: 140px; background-color: $c_blue; }
        div.work-list {
            padding: 65px 0px 205px;margin: 35px 0px; position: relative; display: flex;
            .list { float: left; cursor: pointer; width: 16.4%; text-align: center; position: relative;
                    img { margin: 0 auto; } 
                    span {
                        * { display: block; }
                        &.work {
                            width: 100%; height: 95px; padding: 0px 10px; background: $c_blue; display: block; @include transition(all .4s ease-in-out); @include boxSizing(border-box);
                            &.work-1 { background: url('../images/content/work-1.png'); background-position: top; background-repeat: no-repeat; }
                            &.work-2 { background: url('../images/content/work-2.png'); background-position: top; background-repeat: no-repeat; }
                            &.work-3 { background: url('../images/content/work-3.png'); background-position: top; background-repeat: no-repeat; }
                            &.work-4 { background: url('../images/content/work-4.png'); background-position: top; background-repeat: no-repeat; }
                            &.work-5 { background: url('../images/content/work-5.png'); background-position: top; background-repeat: no-repeat; }
                            &.work-6 { background: url('../images/content/work-6.png'); background-position: top; background-repeat: no-repeat; }

                        }
                        &.work-title { font-weight : normal; display:block; text-transform: uppercase; padding: 25px 0px; color: $c_grey; 
                        }
                        &.tooltip-text { position: absolute; z-index: 5; display: none; font-size: 16px; line-height: 20px; padding: 10px; background: $c_blue; width: 230px; color: $c_white; @include borderRadius(8px); left: 35%;
                                         &::before { content: ''; position: absolute; top: -8px; left: 30px; border-left: 8px solid transparent; border-right: 8px solid transparent; border-bottom: 8px solid $c_blue; 
                                         }
                        }
                    }
                    &:hover {
                        span {
                            &.work-1 , &.work-2 , &.work-3 , &.work-4 , &.work-5 , &.work-6 { background-position: bottom; 
                            }
                            &.work-title { color:$c_blue; font-weight: 700; }
                            /*&.tooltip-text { visibility: visible; }*/
                        }
                    }
            }
            &::after { content: ''; clear: both; }
        }
    }
    /*end of work*/

    /*portfolio*/
    &.testimonial {
        position: relative; padding-bottom: 119px; margin-bottom: 208px;
        .trigger-anim { top: 40%; }
        .trigger-anim-2 { top: auto; bottom: 0px; }
        .wrapper-decor-2 {
            .decor-2 { @include absolute(77px,auto,auto,0px); width: 138px; height: 2px; background: $c_blue; }            
        }
        span.big-bg-decor { @include absolute (auto, 0px, 0px, auto); background: $c_grey2; width: 100%; height: 100%; max-width: 1330px; max-height: 858px; }
        span.decor-right { @include absolute(0px,0px,auto,auto); width: 138px; height: 2px; background: $c_blue; }
        span.decor-3 { @include absolute(0px, 0px,80px,auto); width: 2px; background: $c_blue; }
        .highlight { width: 47%; }
        .wrap-list { width: 53%; }
        .highlight , .wrap-list { padding-top : 77px; @include boxSizing(border-box); float: left; display: block; position: relative;
                                  img { width: 100%; }
        }
        .highlight {
            padding-right: 50px;
            .highlight-titledesc { position: relative; top: 31px; padding: 29px 0px 29px 80px; 
                                   span.decor { @include absolute(0px, auto , auto, 0px); width: 129px; height: 2px; background: $c_blue; content: '';  }
                                   p.highlight-title { font-size: 25px; margin-bottom: 34px; }
                                   p.highlight-desc { font-size: 20px; line-height: 26px; color: $c_grey; margin-bottom: 72px; }
            }
        }
        .wrap-list {
            padding-left: 50px;
            .list {
                position: relative; margin-bottom: 10px;
                .back {
                    @include absolute(0px , 0px , 0px , 0px); background-color: rgba(0,0,0,0.4); @include transition(all 0.35s ease-in-out);
                    .inner {
                        @include absolute(auto,30px, 20px, auto); text-align: right;
                        p.title-testi , a.read-more { color: $c_white; margin: 0px; position: relative; }
                        p.title-testi { font-size: 25px;
                                        &::after { @include absolute(auto,0px,0px,0px); height: 1px; background-color: $c_white; content: ''; opacity: 0; @include transition (all 0.23s ease-in-out); width: 0px; transition-delay: 0.3s; padding-bottom: 0px; margin-bottom: 0px; }
                        }
                        a.read-more { font-size: 16px; opacity: 1; height: 0px; display: block; overflow: hidden; @include transition(height .2s ease-out); 
                                      span.arrow-right { position: relative; display: inline-block; width: 7px; height: 7px; background : url('../images/material/arrow-right.png'); top:-2px; left : 0px; margin-right: 10px; @include transition(all 0.2s ease-out); }
                                      &:hover {
                                          >  span.arrow-right { left: 6px; }
                                      }
                        }
                    }
                    &:hover { background-color: rgba(7,91,122,0.8); @include absolute(10px , 10px , 10px , 10px); 
                              a.read-more { opacity: 1; height: 15px; }
                              p.title-testi {
                                  padding-bottom: 15px; margin-bottom: 10px;
                                  &::after { width:100%; opacity: 1; }
                              }
                    }
                }
            }
            div.container-view-all {
                position: relative; top: -10px; padding: 67px 27px; text-align: right;
                span.decor { @include absolute(0px,0px,0px,auto); width: 2px; height: 100%; background: $c_blue; }
            }
        }
    }
    /*end of portfolio*/

    /*coverage*/
    &.coverage {
        position: relative; margin-bottom: 135px;
        .trigger-anim { top: 40%; }
        .wrapper-decor {
            span.decor { @include absolute(0px,0px,auto,auto); width: 138px;height: 2px; background-color: $c_blue; }
        } 
        .media-container {
            position: relative; padding-bottom: 140px;
            span.decor-2 { @include absolute(0px,auto,0px,0px); width: 2px; background: $c_blue; }
            .list { @include boxSizing(border-box); float: left; width: 25%; position: relative;padding-right: 10px; 
                    .inner { min-height: 530px; 
                             .content-wrapper { 
                                 @include absolute(10px, 20px, 10px, 10px); padding: 10px; background: $c_white;
                                 img { @include absolute(auto, 25px,25px, auto); }
                             }
                             .triangle-corner {
                                 position: relative; width: 108px; height: 112px; display: block; background: url('../images/material/triangle.png'); margin-bottom: 39px;
                             }
                             p.coverage-title { cursor:pointer; font-size: 18px; line-height: 24px; position: relative; color: $c_blue; text-transform: uppercase; font-weight: 600; padding: 0px 10px; margin-bottom: 30px; 
                                &::after { @include absolute(auto,0px,-15px,10px); background: $c_blue; height: 2px; content: ''; max-width: 39px;  }
                                a { height: 78px; display: block; overflow: hidden; }
                             }
                             p.coverage-content { padding: 0px 10px; font-size: 15px; line-height: 21px; max-height: 151px; overflow: hidden;  }
                    }
            }
            &::after { clear: both; content:''; }
        }
    }
    /*end of coverage*/

    /*join*/
    &.join {
        position: relative; margin-bottom: 170px;
        .trigger-anim { top: 40%; }
        .trigger-anim-2 { top: auto; bottom: 0px; }
        span.decor { @include absolute(0px,0px,auto,auto); width: 138px; height: 2px; background: $c_blue; }
        span.decor-2 { @include absolute(auto,auto,-63px,0px); width: 138px; height: 2px; background: $c_blue; }
        .wrap-join { position: relative; background-color: $c_blue; margin-bottom: 63px; }
        .join-desc {
            position: absolute;top: 50%;left: 0;right: 0;transform: translateY(-50%);
            resize: vertical; max-width: 50%; text-align: right; padding-right: 87px; @include boxSizing(border-box);
            h6 { text-transform: uppercase; font-size: 25px; }
            h2 { text-transform: uppercase; font-size: 60px; font-weight: 600; margin-bottom: 20px; }
            p { font-size: 18px; max-width: 70%; float: right; }
            h2 , h6 , p { color: $c_white; }
        }
        .join-form-wrap {
            background-color: $c_grey2; @include boxSizing(border-box); padding: 47px 87px; float: right; width: 50%; 
            .input-group { max-width: 75%; }
        }
        &::after { content: ''; clear: both; }
    }
    /*end of join*/

    /*banner*/
    &.banner {
        .left-content { width: 39.2%; background: $c_blue; height: 422px; position: relative; 
                        .inner { position: absolute; bottom: 50px; right: 47px; text-align: right; max-width: 60%;
                                 h1 { font-size: 45px; line-height: 65px; text-transform: uppercase; font-weight: 700; color: $c_white; }
                                 ul.breadcrumb {
                                     li {
                                         display: inline-block;
                                         a { font-size: 18px; color: $c_white;  }
                                         &::after { position: relative; content: '/'; margin-right: 10px; margin-left: 10px; color: $c_white; }
                                         &:last-child { 
                                             &::after { display:none; }
                                         }
                                     }
                                 }
                        }
                        span.decor { position: absolute; top: 0px; right: 0px; bottom: -32px; width: 2px; background-color: $c_blue; content: ''; }
        }
        .img {
            height: 391px; margin-left: 39%; background-size: cover;
        }
    }
    /*end of banner*/

    /*double column & single column*/
    &.double-column , &.single-column {
        margin-top: 105px; margin-bottom: 130px; position: relative;
        .decor { @include absolute(40px,0px,auto,auto); width: 90px; height: 2px; background: $c_blue; }  
        div.share-socmed {
            text-align : right; margin-top: 13px; margin-bottom: 46px; width: 100%; z-index: 100;
            ul {
                display: block; width: 100%;
                li {
                    display: inline-block;
                    span {
                        display: block;
                        &.fb-circle , &.tw-circle { width: 32px; height: 32px; background-repeat: no-repeat; background-position: top center; @include transition(all 0.34s ease-in-out); 
                                                    &:hover { background-position: bottom center; }
                        }
                        &.fb-circle { background-image: url('../images/material/socmed-fb-circle.png'); }
                        &.tw-circle { background-image: url('../images/material/socmed-tw-circle.png'); }
                    }
                }
            }
        }
        aside {
            float: left; width: 350px;
            b { font-size: 18px; color: $c_blue; text-transform: uppercase; }
            h2 { font-size: 40px; line-height: 45px; color: $c_blue; text-transform: uppercase; font-weight: 900; margin: 10px 0px 20px 0px; }
            form { display: block; position: relative; margin-top: 50px; max-width: 300px; }
            div.title-desc { max-width: 310px; margin-bottom: 30px; }
            a {
                &.btn { max-width: 310px; width: 100%; padding: 25px 25px; }
            }
            ul.socmed-contact {
                display: inline-block;margin-bottom:40px;
                li {
                    display: inline-block; border: none;
                    span {
                        display: block;
                        &.fb-blue , &.yt-blue , &.ig-blue , &.li-blue { width: 50px; height: 35px; background-repeat: no-repeat; background-position: top center; @include transition(all 0.34s ease-in-out); 
                                                                        &:hover { background-position: bottom center; }
                        }
                        &.fb-blue { background-image: url('../images/material/socmed-fb-big.png'); }
                        &.yt-blue { background-image: url('../images/material/socmed-yt-big.png'); }
                        &.ig-blue { background-image: url('../images/material/socmed-ig-big.png'); }
                        &.li-blue { background-image: url('../images/material/socmed-li-big.png'); }
                    }
                }
            }
            ul {
                width: 300px;
                &.side {
                    li { padding: 24px 0px; }
                }
                li { 
                    padding: 24px 0px 0px 0px; border-bottom: 1px solid $c_grey4;
                    a {
                        text-transform: capitalize; color: $c_black; font-family: "Lato",sans-serif;
                        &.active , &:hover { color: $c_blue; }
                        &.active { font-weight:700; }
                    }       
                    &.hasChild {
                        position: relative;
                        span.arrow { width: 20px; height: 13px; background-color: transparent; position: absolute; top: 26px; margin: auto; right:10px; background-image: url('../images/material/arrow-nav-side.png'); cursor: pointer; @include transition(all 0.2s ease-in-out); background-position: top center; 
                                     &.up { background-position: bottom center; }
                                     &:not(.up):hover { @include rotation(180deg); }
                        }
                        > ul {
                            padding-left: 39px; display: block; border-top: 1px solid $c_grey4; margin-top: 25px; width: 100%; @include boxSizing(border-box); display: none;
                            li {
                                a { font-size: 16px; 
                                  &.active { color: $c_blue; }
                                }
                                &:first-child { margin-top: 15px; }
                                &:last-child { padding-bottom: 0px; border-bottom: 0px solid $c_grey4; }
                            }
                        }
                    }
                }
                &.hasborder {
                    li { 
                        border-bottom: 0px solid $c_grey4; position: relative; margin: 10px 0px ;
                        a {
                            font-family: "Quicksand",sans-serif; padding-left: 68px; text-transform: uppercase;
                            &:hover {
                                &::before { background: $c_blue; }
                            }
                            &.active { font-weight:700; font-size: 20px; padding-left: 100px;
                                       &::before { background: $c_blue; width: 79px; height: 4px; top: 20px; }
                            }
                            &::before { position: absolute; width: 49px; height: 1px; background: $c_black; left: 0px; top: 20px; bottom: 0px; margin: auto; content: ''; }

                        }
                        &:first-child {
                            a {
                                &::before { top:30px; bottom: auto; } 
                            }
                        }
                    }
                }
            }
        }
        article {
            margin-left: 378px; max-width: 800px;
            h1 , h2 , h3 , h4 { color: $c_blue; margin-bottom: 18px; display: block; max-width: 90%; font-weight: 700; font-family: "Lato",sans-serif; text-transform: uppercase; 
                                &.full { width: 100%; max-width: 100%; }
            }
            h1 { font-size: 45px; line-height: 60px;  }
            h3 { font-size: 25px; line-height: 34px; position: relative;
                 &.meeting {
                     margin-bottom: 25px;
                     &::after { content:url(../images/material/meeting.png); position:relative; width: 54px; height: 51px; position: relative; display: inline-block; left: 20px; top: 10px; }
                 }
                 &.news {
                     font-size: 30px; color: $c_black; font-weight: normal;
                     b { color: $c_blue; font-size: 30px; }
                 }
            }
            img { display : inline-block; margin : 15px 0px; width: 100%; }
            p , b , ul li , ol li { font-size: 18px; line-height: 28px; margin: 17px 0px; }
            ul , ol { margin-bottom: 31px; 
                      &.qa {
                          p { margin: 0px 0px 37px 0px; }
                      }
            }
            b { margin: 0px 0px; }
            ol { 
                list-style-type: decimal; padding-left: 17px; 
                li { padding-left: 25px; }
            }
            ul {
                li { position: relative; padding-left: 55px;
                     &::before { position:absolute; left: 25px; content: '>'; color: $c_blue; font-weight: 700; }                    
                }
            }
            .bg-nav { position: absolute; width: 175px; height: 90px; background-color: $c_white; right: 0px; bottom: 0px; }

            .title-desc {
                margin-bottom: 52px; margin-top: 15px;
                p { font-size: 18px; text-transform: uppercase; margin: 5px 0px; }
                b { font-size: 20px; text-transform: uppercase; color: $c_blue; }
            }
        }
        &.has-socmed { margin-top: 0px; }
    }
    /*end of double column*/

    /*single column*/
    &.single-column {
        &.has-socmed { margin-top: 90px; }
        article { margin-left: 0px; max-width: 100%; }
        .share-socmed { position: absolute;margin-top: -5px !important; }
    }
    /*end of single column*/

    /*sliders*/
    .slide-nav {
        width: 200px; float: left; z-index: 5;
        button {
            @include appearance(none); @include boxSizing(border-box); border: none; width: 15px; height: 12px; background-size: 100% 200%; background: transparent; cursor: pointer; background-repeat: no-repeat;
            &.slick-prev { 
                margin-bottom: 100px; background-image: url('../images/material/arrow-nav-up.png'); 
            }
            &.slick-next { margin-top: 70px; background-image: url('../images/material/arrow-nav-down.png');  
            }
            &:hover {
                background-position:bottom center;
            } 
        }
        div.item {
            margin: 15px 0px; position: relative; cursor: pointer;
            &::before { content:''; position:relative; width: 49px; height: 1px; background: $c_black; display: inline-block; top:-5px; margin-right: 20px; @include transition(all 0.2s ease-out); }
            &.slick-current {
                &:hover:before,
                    &::before { width: 79px; height: 4px; background: $c_blue; display: inline-block; top:-5px; @include transition(all 0.2s ease-out); } 
            }
            &:hover{
                &:before{width:69px;background: $c_blue;}
            }
        }
    }
    .slide-about-history {
        width: 100%; padding-left: 200px;
    }
    .slide-about-teams , .slide-about-intl {
        position: relative; margin-bottom: 110px;
        a,
        button {
            outline: none;padding: 0;position: absolute;
            @include appearance(none); @include boxSizing(border-box); border: none; width: 13px; height: 22px; background-size: 100% 200%; background: transparent; cursor: pointer; position: absolute; background-repeat: no-repeat; top: 40px; z-index: 10;
            &.slick-prev { 
                left: 20px; background-image: url('../images/material/arrow-nav-left.png');
                &::after { content:''; position:absolute; right: -17px; top: -12px; bottom: -12px; width: 2px; background: #a1a1a1; }
            }
            &.slick-next { 
                left: 64px; background-image: url('../images/material/arrow-nav-right.png');
            } 
            &.slick-arrow:hover{
                background-position:  center bottom;
            }

        }
        .nav{position: absolute;bottom:0;right:0;width:135px;height: 90px;}
        .video {
            position: relative;
            iframe { @include absolute(0,0,0,0); width: 100%; height: 100%; z-index: 10; display: none; }
            .caption {
                @include absolute(15px,0px,16px,0px); @include boxSizing(border-box); max-width: 50%; background-color: rgba(7,91,122,0.8);
                .inner { position: absolute;top: 50%;left: 0;right: 0;transform: translateY(-50%);
                         resize: vertical;text-align: left;padding: 0px 100px 0px 23%; }
                b { display: inline-block; position:relative; font-size: 30px; text-transform: uppercase; font-weight: normal; font-family: 'Quicksand', sans-serif; color: $c_white; padding: 18px 0px; line-height: 35px;
                    &::after { position:absolute; bottom: 0px;right: 0px; content: ''; height: 2px; background: $c_white; width: 170px; }
                }
                p { color:$c_white;padding-left:6.6px;font-size: 24px; text-transform: uppercase; letter-spacing: 6.6px; margin: 30px 0px; }
                .play { @include transition(all 0.28s); position: absolute;top: 50%;right: -51px;transform: translateY(-50%);
                        resize: vertical;text-align: left;width: 99px;height: 97px; cursor: pointer; background: url('../images/material/big-play.png'); background-size: 100%;
                        &:hover { width: 89px; height: 87px; right: -47px; }
                }
            }
        }
    }
    .slide-about-intl {
        margin-bottom: 0px;
        button { top: auto; bottom: 40px; 
                 &.slick-prev { left: auto; right: 102px; }
                 &.slick-next { left: auto; right: 61px; }
        }
    }
    .slide-news-detail {
        .item {
            img.main-image { float: left; max-width: 597px; width: 51%; }
            .content {
                margin-left: 56%;
                .top {
                    margin-bottom: 110px;
                    img { text-align: right; max-width: 110px; }
                }
                .content-desc {
                    p.title { font-size: 25px; text-transform: uppercase; color: $c_blue; margin-bottom: 60px; font-weight: 700; max-width: 55%; position: relative; 
                              &::after { position:absolute; bottom: -20px; left: -20px; width: 101px; height: 4px; background-color: $c_blue; content: ''; }
                    }
                }
            }
        }
        button {
            outline: none; top: auto; bottom: 40px; 
            @include appearance(none); @include boxSizing(border-box); border: none; width: 22px; height: 22px; background-size: 100% 200%; background: transparent; cursor: pointer; position: absolute; background-repeat: no-repeat; top: 40px; z-index: 10; padding: 0px;
            &.slick-prev { 
                left: 47%; top: auto; bottom: 100px;
                &:before { position:absolute; width: 100%; height: 100%; background-image: url('../images/material/arrow-nav-left.png'); content: ""; background-repeat: no-repeat; top: 0; left: 0; }
                &::after { content:''; position:absolute; bottom: -8px; left: -12px; right: -12px; height: 2px; background: #a1a1a1; }
            }
            &.slick-next { 
                left: 47%; top: auto; bottom: 55px;
                &:before { position:absolute; width: 100%; height: 100%; background-image: url('../images/material/arrow-nav-right.png'); content: ""; background-repeat: no-repeat; top: 0; left: 0; }
            } 
        }
    }
    .wrap-slider-news {
        position: relative;
        .white-bg { width: 87px; height: 176px; position: absolute; left: 44%; bottom: 0px; background-color: $c_white; }
    }
    /*end of sliders*/
}

.wrap-popup{
    display: none;position: fixed;top:0;bottom:0;right:0;left:0;width:100%;height:100%;overflow: auto;background:rgba(0,0,0,0.7);z-index:9999;
    .box-popup{
        width: 550px;margin:5% auto;position: relative;
        a{position: absolute;top:-15px;right:-15px;background: url(../images/material/close-pop.png) no-repeat center;width:32px;height:32px;
          display: block;text-indent:-999px;
        }
    }
}
#map{ 
    b{text-transform: capitalize}
    p{font-size: 14px;line-height: 20px}
    table{font-size: 14px !important;line-height: 20px;
          td{padding: 5px}
    }
}
/* footer
----------------------------------------------------------------------------------------------*/

footer {
    padding: 30px 0px 0px 0px; background: $c_grey2;
    .top { position: relative; }
    ul.socmed {
        position: absolute; bottom: 20px; right: 0px; display: inline-block;
        li {
            display: inline-block;
            span {
                display: block;
                &.fb-big , &.yt-big , &.ig-big ,  &.li-big { width: 50px; height: 35px; background-repeat: no-repeat; background-position: top center; @include transition(all 0.34s ease-in-out); 
                                                 &:hover { background-position: bottom center; }
                }
                &.fb-big { background-image: url('../images/material/socmed-fb-big.png'); }
                &.yt-big { background-image: url('../images/material/socmed-yt-big.png'); }
                &.ig-big { background-image: url('../images/material/socmed-ig-big.png'); }
                &.li-big { background-image: url('../images/material/socmed-li-big.png'); }

            }
        }
    }
    figure { margin-bottom: 32px; }
    .col-l-four { padding-right: 40px; @include boxSizing(border-box); 
                  &:nth-child(5) { padding-right:0px; padding-left: 30px; }
                  b { font-size: 16px; margin-bottom: 10px; display: block; color: $c_blue; margin: 9px 0px 15px 0px; }
                  p { font-size: 16px; line-height: 22px; }
    }
    table {
        tr {
            margin: 8px 0px; display: block;
            td {
                a { color: $c_black; }
            }
        }
    }
    form {
        position: relative;
        input[type=email] { width: 100%; @include boxSizing(border-box); background: $c_blue; color: $c_white; font-family: "Lato",sans-serif; 
                            padding:17px 18px 17px 15px; font-size: 14px; border: 1px solid $c_blue; }
        input[type=submit]{
            background: #FFF;width:136px;height:45px;border:1px solid #d7d7d7;font-size:14px;text-transform:uppercase;padding:0;margin: 20px 0 0 0;
            cursor: pointer;@include transition(0.1s linear all);
            &:hover{background:#999;color:#FFF;border-color: #999;}
        }
        @include placeholder{color:#FFF;}

        /*        button { position: absolute; width: 40px; height: 100%; top: 0px; right: 0px; background-color: $c_blue; border: none; background: url('../images/material/arrow-right.png'); background-repeat: no-repeat; background-position: center center; @include transition(all 0.2s ease-out); cursor: pointer; 
                         &:hover {
                             right:5px;
                         }
                }*/
    }
    .seen-on {
        background: $c_dark; margin-top: 64px; padding-bottom: 30px; display: inline-block;
        p { font-size: 16px; color: $c_white; margin-top: 28px; display: block; }
        ul li {
            width: 16.4%; float: left; margin: 20px 0px 5px 0px;
        }
    }
    .rights { margin: 0px; padding: 5px; display: block;background: #2b2b2b; text-align: center; font-size: 12px; color: $c_dark; }
} 

@media screen and(min-width:1px) and(max-width:767px){
    .hide-mobile { display: none !important; }
    section .slide-news-detail {
      button {
        &:before { @include rotation(0deg !important); } 
      }
    }
}
@media screen and(min-width:768px) {
  section .slide-news-detail {
      button {
          &.slick-prev { 
              &:before { @include rotation(90deg); }
          }
          &.slick-next { 
              &:before { @include rotation(90deg); }
          } 
      }
  }

}
@media screen and(min-width:768px) and(max-width:1365px){
    .hide-tablet { display: none !important; }
}
@media screen and(min-width:1024px) and(max-width:1439px){
    .hide-desktop { display: none !important; }
}
@media screen and(min-width:1440px){
    .hide-wide { display: none !important; }
}
@media screen and(max-width:1024px){
    .single-column-desktop {
      article { margin-left: 0px !important; max-width: 100% !important; }
    }
}
@media screen and(min-width:1024px){
    .single-column-desktop {
      article { margin-left: 0px !important; max-width: 100% !important; }
      div.share-socmed {
        position: absolute;
        margin-top: -5px !important;
      }
    }
}